table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border-bottom: 1px solid #00000052;
  text-align: left;
  padding: 8px;
}

.templateTable {
  border: 1px solid #00000052;
}
