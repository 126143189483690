.otpInput {
  width: auto;
  height: 40px;
  margin: 3px;
  border-radius: 4px;
  color: #fff;
  background-color: #096929;
  border: 1px solid #096929;
  padding: 2px;
}

.quotation {
  width: 100px;
  height: 150px;
}
